import { IUserAccount, IUserAccountPassword } from "../../interfaces/user";
import { axiosWithToken, axiosWithoutToken } from "./axiosService";

export const getUsersCustomer = async () => {
    try {
        const response = await axiosWithToken().get(`api/user/customers`)
        return response.data
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const sendBackMail = async (idUser: number | undefined) => {
    try {
        const response = await axiosWithoutToken().post(`api/user/${idUser}/sendemailagain/`)
        return response.data
    } catch (err) {
        console.log(err);
        throw err;
    }
}; 

export const getNotifications = async () => {
    try {
        const response = await axiosWithToken().get(`api/user/notifications`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const watchDocRequest = async (idDocRequest: number) => {
    const data = JSON.stringify({
        idDocRequest : idDocRequest,
    });

    try {
        const response = await axiosWithToken().post(`api/user/watchdoc`, data);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const unwatchDocRequest = async (idDocRequest: number) => {
    const data = JSON.stringify({
        idDocRequest : idDocRequest,
    });

    try {
        const response = await axiosWithToken().post(`api/user/unwatchdoc`, data);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const verifyMfa = async (code: string, email: string, password: string) => {
    const data = JSON.stringify({
        token : code,
        eMail : email,
        password: password,
    });

    try{
        const response = await axiosWithToken().post(`api/user/verifytotp`, data);
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const configMfa = async () => {
    try {
        const response = await axiosWithToken().patch(`api/user/2fatotp/set`);
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const verifytotpForDesactivated = async (code : string) => {
    const data = JSON.stringify({
        token: code,
    });
    try {
        const response = await axiosWithToken().patch(`api/user/2fatotp/unset`, data);
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const enableMfa = async (code: string) => {
    const data = JSON.stringify({
        token : code,
    });
    try {
        const response = await axiosWithToken().patch(`api/user/2fatotp/enable`, data);
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const userClientAssignment = async () => {
    try {
        const response = await axiosWithToken().get(`/api/user/customers`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const userPartnerAssignment = async () => {
    try {
        const response = await axiosWithToken().get(`/api/user/partners`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const getUserDashboardDataCount = async () => {
    try {
        const response = await axiosWithToken().get(`/api/user/dashboard/datas`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const getInfoDashboardNbOfRequest = async () => {
    try {
        const response = await axiosWithToken().get(`/api/user/dashboard/statistics`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const verifyMailIfExist = async (email: string) => {
    try {
        const response = await axiosWithoutToken().get(`api/user/emailvalid?email=${email}`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const createAccount = async (data: IUserAccount, token: string | null) => {
    try {
        const response = await axiosWithToken().post(`api/user/register?token=${token}`, data)
        return response
    } catch (err) {
        throw err;
    }
}

export const createAccountWithGoogle = async (tokenGoogle: string | null, invationToken: string | null) => {
    try {
        const response = await axiosWithToken().post(`api/user/register/google?token=${tokenGoogle}&invation_token=${invationToken}`)
        return response
    } catch (err) {
        throw err;
    }
}

export const createAccountWithOffice = async (idToken: string | null, accessToken: string| null,  invationToken: string | null) => {
    try {
        const response = await axiosWithToken().post(`api/user/register/office?token=${idToken}&accessToken=${accessToken}&invation_token=${invationToken}`)
        return response
    } catch (err) {
        throw err;
    }
}

export const updateProfil = async (data: IUserAccountPassword) => {
    try {
        const response = await axiosWithToken().patch(`api/user`, data)
        return response
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const deleteProfile = async () => {
    try {
        const response = await axiosWithToken().delete(`api/user`)
        return response
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const linkGoogle = async (token: string) => {
    try {
        const response = await axiosWithToken().post(`api/user/link/google?token=${token}`)
        return response
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const linkOffice = async (idToken: string, accessToken: string) => {
    try {
        const response = await axiosWithToken().post(`api/user/link/office?token=${idToken}&accessToken=${accessToken}`)
        return response
    } catch (err) {
        console.log(err);
        throw err;
    }
}