import { useNotifsStore } from "../../stores/Utils/notifs";

const Help = () => {
  const { showHelp } = useNotifsStore();

  return (
    <>
      {showHelp && (
        <div
          className={`absolute top-[340px] left-14 z-40 right-0 font-BarlowCondensed shadow-lg p-4 rounded-md w-[300px] xl:w-[450px] h-[150px] bg-white flex flex-col justify-center items-center`}
        >
          <p className="text-center font-BarlowCondensed text-md w-full bg-yellow-trio rounded-md sticky top-0 z-10">
            Aide
          </p>
          <p className="text-center font-BarlowCondensed text-md w-full rounded-md ">
            Pour tous renseignements, <br />
            veuillez contacter le support technique: <br />
          </p>
          <a
            href="mailto:dev@trionode.com"
            className="font-bold text-center w-full"
          >
            dev@trionode.com
          </a>
        </div>
      )}
    </>
  );
};

export default Help;
