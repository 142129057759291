import { AxiosError } from "axios";
import { axiosWithToken, axiosWithoutToken } from "./axiosService";
import { toast } from "react-toastify";
import { ErrorResponse } from "../../interfaces/error";
import { showToastError } from "../Toasts/toasts";

// Login
export const login = async (email: string, password: string) => {
  const data = JSON.stringify({
    eMail: email,
    password: password,
  });

  try {
    const response = await axiosWithoutToken().post(`api/user/login`, data);
    return response;
  } catch (error) {
    const axiosError = error as AxiosError;
    const errorResponse = axiosError?.response?.data as ErrorResponse;
    const errorResponseStatus = axiosError?.response?.status as unknown as number;
    if (errorResponseStatus === 429) {
      showToastError("Trop de tentatives de connexion, veuillez réessayer dans 15 min");
    } else if (typeof errorResponse === "string" && errorResponse === "eMail 2FA sent") {
      throw new Error("eMail 2FA sent"); 
    } else if (typeof errorResponse === "string" && errorResponse === "2faTotpRequired") {
      throw new Error("2faTotpRequired"); 
    } else if (typeof errorResponse === "string" && errorResponse === "googleAccount") {
      throw new Error("googleAccount"); 
    } else if (typeof errorResponse === "string" && errorResponse === "officeAccount") {
      throw new Error("officeAccount"); 
    } else if (typeof errorResponse === "string" && errorResponse === "accountNotCreated") {
      throw new Error("accountNotCreated"); 
    } else if (typeof errorResponse === "string" && errorResponse === "Mot de passe incorrect") {
      throw new Error("Mot de passe incorrect"); 
    } else {
      toast.error(errorResponse?.message);
      throw new Error;
    }
  }
};

//Login with google
export const logWithGoogle = async (token: string) => {
  try {
      const response = await axiosWithoutToken().post(`api/user/login/google?token=${token}`)
      return response
  } catch (error) {
      const axiosError = error as AxiosError;
      const errorResponse = axiosError?.response?.data as ErrorResponse;
      const errorResponseStatus = axiosError?.response?.status as unknown as number;
      if (errorResponseStatus === 500) {
       if (typeof errorResponse === "string" && errorResponse === "User not invited") {
        throw new Error("User not invited"); 
      } else {
        throw new Error;
      }
    }
  }
}

//Login with office
export const logWithOffice = async (idToken: string, accessToken: string) => {
  try {
      const response = await axiosWithoutToken().post(`api/user/login/office?token=${idToken}&accessToken=${accessToken}`)
      return response
  } catch (error) {
      const axiosError = error as AxiosError;
      const errorResponse = axiosError?.response?.data as ErrorResponse;
      const errorResponseStatus = axiosError?.response?.status as unknown as number;
      if (errorResponseStatus === 500) {
       if (typeof errorResponse === "string" && errorResponse === "User not invited") {
        throw new Error("User not invited"); 
      } else {
        throw new Error;
      }
    }
  }
}

// This function is used to reset the password when the user forgot it
export const resetPassword = async (token: string|null, password: string|null) => {
  try {
    const response = await axiosWithoutToken().post(`api/user/resetpassword`, {
      token: token,
      password: password,
    });
    return response;
  } catch (error) {
    const axiosError = error as AxiosError;
    const errorResponse = axiosError?.response?.data as ErrorResponse;
    toast.error(`${errorResponse?.message}`);
  }
};


// This function is used to get the user information when the refresh page is called
export const getUserInformation = async () => {
  try {
    const response = await axiosWithToken().get(`api/user/whoami`);
    return response;
  } catch (error) {
    throw error;
  }
};

// This function is for reset password when user send his email
export const resetPasswordRequest = async (email: string) => {
  try {
    const response = await axiosWithoutToken().post(`api/user/requestresetpass`, {
      eMail: email,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// This function is for accept CGU
export const acceptCGU = async () => {
  try {
    const response = await axiosWithToken().post(`api/user/acceptcgu`);
    return response;
  } catch (error) {
    throw new Error("Une erreur est survenue lors de la connexion.");
  }
};

export const logout = async () => {
  try {
    const response = await axiosWithToken().post(`api/user/logout`);
    return response;
  } catch (error) {
    throw new Error("Une erreur est survenue lors de la déconnexion.");
  }
}

export const check2fa = async (code : string | undefined, eMail: string | undefined, isChecked: boolean) => {
  const data = JSON.stringify({
      eMail: eMail,
      code: code,
      remember: isChecked
  });
  try {
      const response = await axiosWithoutToken().post(`api/user/check2fa/`, data)
      return response.data
  } catch (error) {
      const axiosError = error as AxiosError;
      const errorResponse = axiosError?.response?.data as ErrorResponse;
      throw errorResponse;  
  }
}