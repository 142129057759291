import { ToastContainer } from "react-toastify";
import Header from "../../components/Layout/Header";
import SideBar from "../../components/Layout/SideBar";
import Footer from "../../components/Layout/Footer";
import { useEffect } from "react";
import { useProfil } from "../../stores/Layout/profil";
import { getUserInformation } from "../../services/Api/loginService";
import {
  linkGoogle,
  linkOffice,
  updateProfil,
} from "../../services/Api/userService";
import { showToastError, showToastSuccess } from "../../services/Toasts/toasts";
import useModal from "../../hooks/useModal";
import DeleteProfil from "../../components/Layout/DeleteProfil";
import { useTokenContext } from "../../context/tokenContext";
import { GoogleLogin } from "@react-oauth/google";
import { useMsal } from "@azure/msal-react";
import microsoft from "../../assets/logos/microsoft.png";
import { useMfaStore } from "../../stores/Utils/mfa";
import Mfa from "../../components/Service/Mfa";

const Profil = () => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    title,
    setTitle,
    lastPassword,
    setLastPassword,
    newPassword,
    setNewPassword,
    reset,
  } = useProfil();
  const { accountType } = useTokenContext();
  const { setShowMfa, showMfa } = useMfaStore();

  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    getUserInformation()
      .then((res) => {
        setFirstName(res.data.firstname);
        setLastName(res.data.lastname);
        setEmail(res.data.eMail);
        setPhone(res.data.tel);
        setTitle(res.data.titre);
      })
      .catch((err) => console.log(err));
  }, [reset]);

  const handleUpdateProfil = () => {
    const data = {
      lastName,
      firstName,
      email,
      lastPassword,
      newPassword,
      titre: title,
      tel: phone,
    };

    updateProfil(data)
      .then(() => {
        showToastSuccess("Votre profil à bien été modifié");
        setNewPassword("");
        setLastPassword("");
        reset();
      })
      .catch((err) => console.log(err));
  };

  // Login with google
  const loginWithGoogle = (credentialResponse: any) => {
    linkGoogle(credentialResponse.credential)
      .then(() => {
        showToastSuccess("Votre compte à bien été lié");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loginErrror = () => {
    showToastError("Une erreur est survenue lors de la connexion.");
  };

  const { instance } = useMsal();

  // Login with office
  const handleLoginOffice = () => {
    instance
      .loginPopup({
        scopes: ["user.read"],
      })
      .then((response) => {
        linkOffice(response.idToken, response.accessToken)
          .then(() => {
            showToastSuccess("Votre compte à bien été lié");
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="w-screen h-screen bg-white flex flex-col justify-start items-start overflow-auto">
      <Header />
      <div className="w-full flex justify-start items-start overscroll-auto">
        {showMfa ? <Mfa /> : null}
        <SideBar />
        <ToastContainer position="top-center" />
        {isModalOpen ? modalContent : null}
        <div className="flex flex-col justify-start items-center w-11/12 h-screen m-auto">
          <div className="w-11/12 flex justify-start sm:justify-between items-center 3xl:my-5 xl:my-3">
            <h2 className="my-2 text-nxm-darkGray font-light 2xl:text-3xl text-xl mr-5 ml-1 font-K2D ">
              Votre profil :
            </h2>
          </div>
          <div className="hidden md:flex flex-col justify-around items-center w-9/12 3xl:my-10 my-2 m-auto">
            <p className="text-grey-trio 2xl:mb-10 mb-4 text-start w-full pl-4">
              Vous pouvez mettre à jour vos informations personnels
            </p>
            <div className="flex justify-center items-center 2xl:gap-5 gap-2 w-full 2xl:mt-10">
              <div className="flex flex-col justify-start items-start w-1/2 pl-4">
                <p>Prénom :</p>
                <input
                  type="text"
                  disabled={
                    accountType === "google" || accountType === "office"
                  }
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  className={`${
                    accountType === "google" || accountType === "office"
                      ? "bg-gray-200"
                      : "bg-white "
                  } border-b-[1px] w-1/2 mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1`}
                />
              </div>
              <div className="flex flex-col justify-start items-start w-1/2">
                <p>Nom :</p>
                <input
                  type="text"
                  disabled={
                    accountType === "google" || accountType === "office"
                  }
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={`${
                    accountType === "google" || accountType === "office"
                      ? "bg-gray-200"
                      : "bg-white "
                  } border-b-[1px] w-1/2 mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1`}
                />
              </div>
            </div>
            <div className="flex justify-around items-center 2xl:gap-5 gap-2 w-full">
              <div className="flex flex-col justify-start items-start w-1/2 pl-4">
                <p>Email :</p>
                <input
                  type="text"
                  disabled={
                    accountType === "google" || accountType === "office"
                  }
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`${
                    accountType === "google" || accountType === "office"
                      ? "bg-gray-200"
                      : "bg-white "
                  } border-b-[1px] w-1/2 mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1`}
                />
              </div>
              <div className="flex flex-col justify-start items-start w-1/2">
                <p>Phone :</p>
                <input
                  type="text"
                  disabled={
                    accountType === "google" || accountType === "office"
                  }
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className={`${
                    accountType === "google" || accountType === "office"
                      ? "bg-gray-200"
                      : "bg-white "
                  } border-b-[1px] w-1/2 mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1`}
                />
              </div>
            </div>
            <div className="flex justify-around items-center 2xl:gap-5 gap-2 w-full">
              <div className="flex flex-col justify-start items-start w-full pl-4">
                <p>Titre :</p>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="border-b-[1px] w-1/2 mb-5 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                />
              </div>
            </div>
            {accountType === "local" ? (
              <div className="flex justify-around items-center gap-5 w-full bg-light-lavender bg-opacity-20 p-4 border-[1px] rounded-md">
                <div className="flex flex-col justify-start items-start w-1/2">
                  <p>Ancien mot de passe :</p>
                  <input
                    type="password"
                    value={lastPassword}
                    onChange={(e) => setLastPassword(e.target.value)}
                    className="border-b-[1px] w-1/2 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                  />
                </div>
                <div className="flex flex-col justify-start items-start w-1/2">
                  <p>Définir votre nouveau mot de passe :</p>
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="border-b-[1px] w-1/2 h-10 rounded-md bg-white focus:outline-none mt-1 shadow-xl pl-1"
                  />
                </div>
              </div>
            ) : null}
            {accountType === "local" ? (
              <div className="flex mt-4 justify-around items-center gap-5 w-full bg-light-lavender bg-opacity-20 p-4 border-[1px] rounded-md">
                <div className="flex flex-col justify-start items-start w-full ml-3">
                  <p>Lier son compte:</p>
                  <div className="flex justify-start items-center w-full mt-2">
                    <GoogleLogin
                      onSuccess={loginWithGoogle}
                      onError={loginErrror}
                    />
                    <button
                      className="border-[#3c4043] bg-[#F8FAFE] text-[#3c4043] font-roboto rounded-md w-[222px] text-[15px] focus:outline-none h-10 border-[1px] flex justify-center items-center ml-4"
                      onClick={handleLoginOffice}
                    >
                      <img
                        src={microsoft}
                        alt="logo microsoft"
                        className="h-5 w-5 mr-4"
                      />
                      <p>Se connecter avec Office</p>
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {accountType === "local" ? (
              <div className="flex mt-4 justify-around items-center gap-5 w-full bg-light-lavender bg-opacity-20 p-4 border-[1px] rounded-md">
                <div className="flex flex-col justify-start items-start w-full ml-3">
                  <p>Authentificator: </p>
                  <div className="flex justify-start items-center w-full mt-2">
                    <button
                      className="flex justify-start items-center mt-4 w-full learn-more pb-4"
                      onClick={() => setShowMfa(true)}
                    >
                      <span className="circle" aria-hidden="true">
                        <span className="icon arrow"></span>
                      </span>
                      <p className="text-md text-grey-trio button-text">
                        Configurer MFA
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="w-full flex items-center justify-end mt-10">
              <button
                className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                onClick={() =>
                  openModal(<DeleteProfil closeModal={closeModal} />)
                }
              >
                Supprimer votre compte
              </button>
              <button
                className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center  uppercase  shadow hover:shadow-lg outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={() => handleUpdateProfil()}
              >
                Modifier
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profil;
