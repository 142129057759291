import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import SelectManager from "../Utils/SelectManager";
import { useUtilsStore } from "../../../../stores/Utils/selects";
import SelectServiceMission from "../Utils/SelectServiceMission";
import SelectYear from "../Utils/SelectYear";
import SelectDate from "../Utils/SelectDate";
import { useEffect } from "react";
import {
  createMission,
  createUserCustomer,
  downloadMissionLetter,
  getMissionDataByCustomer,
  getPartnerAssociated,
  getUserCustomer,
  getUsersCustomerToMission,
  getUsersPartnerToMission,
  postMissionFile,
  updateMission,
} from "../../../../services/Api/customerService";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";
import UploadMission from "../Utils/UploadMissionLetter";
import {
  splitMissionName,
  verifyEmail,
} from "../../../../services/Utils/functionService";
import { IUserManagerMission } from "../../../../interfaces/user";
import { useParams } from "react-router-dom";
import { useTokenContext } from "../../../../context/tokenContext";
import SelectPeriodMission from "../Utils/SelectPeriodMission";
import SelectMonth from "../Utils/SelectMonth";
import download from "../../../../assets/icons/download.svg";

const PartnerModalCreateUpdateMission = () => {
  const {
    choiceModal,
    setChoiceModal,
    usersClients,
    setUsersClients,
    collabsAssignedToClient,
    setCollabsAssignedToClient,
    checkboxValuesCollabs,
    setCheckboxValuesCollabs,
    checkboxValuesClients,
    setCheckboxValuesClients,
    checkboxRelaunchAuto,
    setCheckboxRelaunchAuto,
    dataMission,
    setDataMission,
    newUser,
    setNewUser,
    createNewClient,
    setCreateNewClient,
    resetNewUser,
    resetMission,
    missionId,
    missionDetails,
    setMissionDetails,
    setErrorLimitMission,
  } = usePartnerMissionStore();
  const { idCustomer } = useParams();
  const { userId } = useTokenContext();
  const {
    resetSelects,
    selectedManager,
    setSelectedManager,
    isRenewable,
    setIsRenewable,
  } = useUtilsStore();

  const handleResetAll = () => {
    resetMission();
    resetSelects();
    setChoiceModal("");
    setCheckboxValuesClients({});
    setCheckboxValuesCollabs({});
    setIsRenewable(false);
  };

  // Function to get collaborateurs assign to a client
  useEffect(() => {
    if (idCustomer)
      setDataMission({
        ...dataMission,
        idCustomer: Number(idCustomer),
      });
    // This is for collabs
    getPartnerAssociated(Number(idCustomer))
      .then((data) => {
        // Supprimer le manager de la liste
        const filteredData = data.filter(
          (collab: any) => collab.id !== selectedManager
        );

        const mappedData: { [key: number]: IUserManagerMission } = {};

        filteredData.forEach((assignUser: IUserManagerMission) => {
          mappedData[assignUser.id] = assignUser;
        });
        if (choiceModal === "create") {
          const checkboxValues: { [key: number]: boolean } = {};
          Object.values(mappedData).forEach(
            (assignUser: IUserManagerMission) => {
              if (assignUser.id === userId) {
                checkboxValues[assignUser.id] = true;
              } else {
                checkboxValues[assignUser.id] = false;
              }
            }
          );

          setCollabsAssignedToClient(Object.values(mappedData));
          setCheckboxValuesCollabs(checkboxValues);
        } else {
          // UPDATE MISSION
          if (idCustomer)
            getUsersPartnerToMission(Number(idCustomer), missionId)
              .then((res) => {
                // Si pas de collaborateurs assignés
                if (res.status === 204) {
                  setCollabsAssignedToClient(Object.values(mappedData));
                } else {
                  const checkboxValues: { [key: number]: boolean } = {};

                  Object.values(mappedData).forEach((assignUser: any) => {
                    checkboxValues[assignUser.id] = res.data.some(
                      (user: any) => user.id === assignUser.id
                    );
                  });

                  setCollabsAssignedToClient(Object.values(mappedData));
                  setCheckboxValuesCollabs(checkboxValues);
                }
              })
              .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));

    if (choiceModal === "update") {
      getMissionDataByCustomer(Number(idCustomer), Number(missionId))
        .then((data) => {
          setMissionDetails(data);
          setDataMission(data);
          setSelectedManager(data.managerId);
          if (data.autoRemind === true) {
            setCheckboxRelaunchAuto(true);
          } else {
            setCheckboxRelaunchAuto(false);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [idCustomer, choiceModal]);

  useEffect(() => {
    // This is for clients
    getUserCustomer(idCustomer)
      .then((users) => {
        setUsersClients(users);
        const clientCheckboxValues: { [key: number]: boolean } = {};
        users.forEach((user: any) => {
          clientCheckboxValues[user.id] = false;
        });
        setCheckboxValuesClients(clientCheckboxValues);

        // UPDATE
        if (choiceModal === "update") {
          getUsersCustomerToMission(Number(idCustomer), missionId)
            .then((assignedUsers) => {
              const clientCheckboxValues: { [key: number]: boolean } = {};
              users?.forEach((user: any) => {
                clientCheckboxValues[user.id] = assignedUsers?.some(
                  (assignedUser: any) => assignedUser.id === user.id
                );
              });

              setUsersClients(users);
              setCheckboxValuesClients(clientCheckboxValues);
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, [idCustomer, choiceModal, createNewClient]);

  const handleCheckboxChangeCollabs = (value: number, checked: boolean) => {
    setCheckboxValuesCollabs({ ...checkboxValuesCollabs, [value]: checked });
  };

  const handleCheckboxChangeClients = (value: number, checked: boolean) => {
    setCheckboxValuesClients({ ...checkboxValuesClients, [value]: checked });
  };

  const handleCreateOrUpdateMission = async () => {
    if (dataMission.dueDate === null && !isRenewable) {
      showToastError("Veuillez renseigner une date de fin de mission");
      return;
    } else if (dataMission.dateExercice === null) {
      showToastError("Veuillez renseigner une date d'exercice");
      return;
    } else if (dataMission.categoryId === 0) {
      showToastError("Veuillez renseigner une catégorie de mission");
      return;
    } else if (dataMission.managerId === 0) {
      showToastError("Veuillez sélectionner un responsable");
      return;
    }
    try {
      if (idCustomer !== "0") {
        let dataForSend;
        if (!isRenewable) {
          dataForSend = {
            categoryId: dataMission.categoryId,
            dateExercice: dataMission.dateExercice,
            managerId: dataMission.managerId,
            autoRemind: checkboxRelaunchAuto,
            dueDate: dataMission.dueDate,
            partnerUsers: Object.entries(checkboxValuesCollabs)
              .filter(([_, value]) => value)
              .map(([key]) => Number(key)),
            existingCustomerUsers: Object.entries(checkboxValuesClients)
              .filter(([_, value]) => value)
              .map(([key]) => Number(key)),
          };
        } else {
          const startMonth: any = dataMission.startMonth;
          const parsedStartMonth = new Date(startMonth);
          const dueDate = new Date(parsedStartMonth);

          dueDate.setFullYear(dueDate.getFullYear() + 1);

          dataForSend = {
            categoryId: dataMission.categoryId,
            dateExercice: dataMission.dateExercice,
            managerId: dataMission.managerId,
            autoRemind: checkboxRelaunchAuto,
            dueDate: dueDate,
            partnerUsers: Object.entries(checkboxValuesCollabs)
              .filter(([_, value]) => value)
              .map(([key]) => Number(key)),
            existingCustomerUsers: Object.entries(checkboxValuesClients)
              .filter(([_, value]) => value)
              .map(([key]) => Number(key)),
            startMonth: dataMission.startMonth,
            periodType: dataMission.periodType,
          };
        }

        let missionResponse;
        let idMission;
        if (choiceModal === "update") {
          missionResponse = await updateMission(
            Number(idCustomer),
            missionId,
            dataForSend
          );
        } else {
          // Création de la mission avec les utilisateurs assignés
          missionResponse = await createMission(idCustomer, dataForSend);

          if (missionResponse) {
            idMission = Number(missionResponse.id);
            if (dataMission.file !== null) {
              // Envoi du fichier de mission
              await postMissionFile(
                Number(idCustomer),
                dataMission.file,
                Number(idMission)
              );
            }
          }
        }

        if (missionResponse) {
          handleResetAll();
          choiceModal === "create"
            ? showToastSuccess("La mission a bien été créée !")
            : showToastSuccess("La mission a bien été modifié !");
        } else {
          showToastError("Une erreur est survenue");
        }
      }
    } catch (error) {
      handleResetAll();
      if (typeof error === "object" && error !== null && "response" in error) {
        const typedError = error as { response: { data: { message: string } } };
        if (
          typedError.response.data.message &&
          typeof typedError.response.data.message === "string" &&
          typedError.response.data.message.trim() ===
            "Vous avez atteint votre quota de mission active, ou bien votre abonnement est arrivé a expiration, merci de vous rapprochez du service commercial"
        ) {
          setErrorLimitMission(true);
        } else {
          showToastError("Une erreur est survenue");
        }
      } else {
        showToastError("Une erreur est survenue");
      }
    }
  };

  const handleCreateUser = () => {
    if (!verifyEmail(newUser.eMail)) {
      showToastError("Veuillez renseigner un email valide");
      return;
    }
    createUserCustomer(idCustomer, newUser)
      .then(() => {
        setCreateNewClient(!createNewClient);
        showToastSuccess("L'utilisateur a bien été créé !");
        resetNewUser();
      })
      .catch((err) => console.log(err));
  };

  const handleUserChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const value = event.target.value;
    let updatedUsers = { ...newUser };
    updatedUsers = { ...updatedUsers, [field]: value };
    setNewUser(updatedUsers);
  };

  const handleDownload = () => {
    downloadMissionLetter(Number(idCustomer), Number(missionId))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${missionDetails?.name}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  return (
    choiceModal !== "" && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none h-screen font-BarlowCondensed ">
          <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[90%] 2xl:min-w-[55%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-center justify-center p-4 border-b border-solid border-slate-200 rounded-t">
                {choiceModal === "create" ? (
                  <h3 className="text-xl sm:text-3xl font-semibold font-K2D text-center text-grey-trio">
                    Création d'une mission
                  </h3>
                ) : (
                  <h3 className="text-xl sm:text-3xl font-semibold font-K2D text-center text-grey-trio">
                    Modification de la mission{" "}
                    {splitMissionName(missionDetails?.name)}
                  </h3>
                )}
              </div>
              {/*body*/}
              <div className="w-full m-auto flex flex-col items-center justify-start overflow-y-auto">
                {/* Part for create a mission */}
                {choiceModal === "create" ? (
                  <div className="flex flex-col justify-center items-center w-10/12 mt-4 m-auto">
                    <div className="flex justify-start items-center w-full text-lg">
                      <SelectManager />
                      <SelectServiceMission />
                      <SelectYear />
                      {!isRenewable ? <SelectDate /> : <SelectPeriodMission />}
                    </div>
                    {isRenewable ? (
                      <div className="flex justify-center items-center w-10/12 mt-4 m-auto">
                        <p className="mr-4 text-lg">
                          Date de début de la mission renouvelable :
                        </p>
                        <SelectMonth />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="flex justify-between items-center w-8/12 mt-4 m-auto">
                    <SelectManager />
                    {missionDetails.isThereALetter && (
                      <div className="flex justify-start items-center">
                        <p className="mr-4">Lettre de mission :</p>
                        <button onClick={() => handleDownload()}>
                          <img src={download} alt="telecharger" />
                        </button>
                      </div>
                    )}
                    <div className="flex justify-start items-center">
                      <p>Relance des requêtes en retard automatique : </p>
                      <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in ml-4">
                        <input
                          type="checkbox"
                          name="toggle"
                          id="toggle"
                          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                          checked={checkboxRelaunchAuto}
                          onChange={() => {
                            setCheckboxRelaunchAuto(!checkboxRelaunchAuto);
                          }}
                        />
                        <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                      </div>
                    </div>
                  </div>
                )}

                <hr className="my-4 border-[1px] w-1/2" />

                {/* Part for collabs */}
                <div className="text-md flex w-11/12 m-auto h-auto">
                  <div className="w-1/3 flex flex-col items-center justify-start mb-2 text-xs sm:text-base">
                    <p className="text-lg mb-4">Collaborateurs affectés :</p>

                    <div className="w-full bg-white border-[1px] rounded-md m-auto h-64 overflow-y-scroll shadow-xl">
                      {collabsAssignedToClient?.map((user) => (
                        <div
                          key={user?.id}
                          className="flex flex-col items-start justify-start text-lg  border-b-[1px] border-slate-200 "
                        >
                          <div className="flex justify-center items-center p-4 ">
                            <input
                              type="checkbox"
                              checked={checkboxValuesCollabs[user?.id] || false}
                              onChange={() => {
                                handleCheckboxChangeCollabs(
                                  user?.id,
                                  !checkboxValuesCollabs[user?.id]
                                );
                              }}
                              className="mr-2 "
                            />
                            <label>
                              {user?.firstName === ""
                                ? user?.eMail
                                : `${user?.firstName} ${user?.lastName}`}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="border-[1px] w-20 rotate-90 m-auto" />
                  <div className="w-1/3 flex flex-col items-center justify-start mb-2 text-lg">
                    <p className="text-lg mb-4">Création client :</p>

                    <div className="w-full bg-white border-[1px] rounded-md m-auto h-64 overflow-y-scroll shadow-xl">
                      <div className="w-full h-full flex flex-col justify-between">
                        <div className="w-full flex flex-col justify-start items-center">
                          <p className="w-2/3 mt-2 text-grey-trio">Email:</p>
                          <input
                            type="email"
                            className="border-b-[1px] border-grey-trio w-2/3 mt-2 focus:outline-none"
                            minLength={10}
                            maxLength={200}
                            value={newUser.eMail}
                            placeholder="Email"
                            onChange={(e) => handleUserChange(e, "eMail")}
                            required
                          />
                          {/* <input
                            type="text"
                            className="border-b-[1px] border-nxm-darkGray w-2/3 my-5 focus:outline-none"
                            value={newUser.titre}
                            placeholder="Titre"
                            onChange={(e) => handleUserChange(e, "titre")}
                            required
                          /> */}
                        </div>
                        <div className="w-full flex justify-end items-center">
                          <button
                            onClick={() => handleCreateUser()}
                            className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-1 text-center me-2 mb-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                          >
                            <p>Ajouter</p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="border-[1px] w-20 rotate-90 m-auto" />
                  <div className="w-1/3 flex flex-col items-center justify-start  mb-2 text-xs sm:text-base">
                    <div className=" flex justify-center items-center mb-2">
                      <p className="text-lg mr-4 ">Clients affectés :</p>
                    </div>

                    <div className="w-full bg-white border-[1px] rounded-md m-auto h-64 overflow-y-scroll shadow-xl">
                      {usersClients?.map((user) => (
                        <div
                          key={user?.id}
                          className=" flex flex-col items-start justify-start text-lg border-b-[1px] border-slate-200 "
                        >
                          <div className="flex justify-center items-center p-4 ">
                            <input
                              type="checkbox"
                              checked={checkboxValuesClients[user?.id] || false}
                              onChange={() => {
                                handleCheckboxChangeClients(
                                  user?.id,
                                  !checkboxValuesClients[user?.id]
                                );
                              }}
                              className="mr-2 "
                            />
                            {user.firstName === "" ? (
                              <div className="flex flex-col justify-center items-center">
                                <p>{user.eMail}</p>
                                <p className="font-thin italic text-sm ml-1">
                                  compte pas encore créé
                                </p>
                              </div>
                            ) : (
                              <p>
                                {user.firstName} {user.lastName}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {choiceModal === "create" ? (
                  <>
                    <hr className="my-4 border-[1px] w-1/2" />
                    <UploadMission />{" "}
                  </>
                ) : null}
              </div>

              {/*footer*/}
              <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-300 background-transparent hover:text-red-500 font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => handleResetAll()}
                >
                  Annuler
                </button>
                {choiceModal === "create" ? (
                  <button
                    className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleCreateOrUpdateMission}
                  >
                    Créer
                  </button>
                ) : (
                  <button
                    className="text-white bg-gradient-to-r from-light-lavender via-light-lavender to-dark-lavender hover:bg-gradient-to-br font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleCreateOrUpdateMission}
                  >
                    Modifier
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default PartnerModalCreateUpdateMission;
